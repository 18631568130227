import styled from 'styled-components';
import { breakPoints } from 'utils/breakPoints.js';

export const buttonData = [
    {
        id: 'apple',
        Icon: styled.img.attrs({ src: `/icons/apple.svg` })`width: 50px;`,
        mainText: 'App Store',
        subText: '下载',
        link: 'https://apps.apple.com/br/app/lee-bank/id6443819260',
    },
    {
        id: 'google',
        Icon: styled.img.attrs({ src: `/icons/google-play.svg` })`width: 50px;`,
        mainText: 'Google Play',
        subText: '去商店下载',
        link: 'https://play.google.com/store/apps/details?id=com.leebank.app&hl=pt_BR&gl=US',
    },
];


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0 50px;

    @media ${breakPoints.xs} {
        padding: 0 10px 40px;
    }
   
`;

export const AppDownloadSectionContainer = styled.div.attrs({
})`          
    position: relative;

    padding-bottom: 80px;
    background-color: ${props => props.theme.palette.colors.white};
`;

// export const QrCode = styled.img.attrs({
//     src: `/icons/qrcode.svg`
// })`           
//     /* width: 539px;
//     position: absolute;
//     top: 60px;
//     left: -308px; */
// `;


export const AppDownloadSectionTitleContent = styled.div.attrs({
})`            
    padding: 60px 20px;
`;


export const AppDownloadSectionTitle = styled.div.attrs({
})`            
    font-size: 88px;
    text-align: center;
    color: ${props => props.theme.palette.colors.white};

    background: linear-gradient(262deg, #19FB9B -8.01%, #B352FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;

    @media ${breakPoints.xs} {
        font-size: 28px;
        line-height: 33.04px;
        
        padding-bottom: 20px;
    }

    @media ${breakPoints.lg} {
        font-size: 10vw;
        line-height: normal;
        padding-bottom: 20px;
    }
`;

export const AppDownloadSectionText = styled.div.attrs({
})`            
    font-size: 20px;
    color: ${props => props.theme.palette.colors.purple};
    font-weight: 400;
    line-height: 27.3px;
    text-align: center;
    text-transform: uppercase;
    
`;

export const RowCentred = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    flex-wrap: wrap;
`;

export const WrapQrcode = styled.div``;

export const ColGroupButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`;

export const Button = styled.button`
    padding: 15px 20px;
    width: 100%;
    background: ${props => props.theme.palette.colors.black};
    border-radius: 12px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: white;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    gap: 15px;

    &:hover {
        background: ${props => props.theme.palette.colors.darkGray};
    }
`;

export const GroupText = styled.a`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-decoration: none;
    color: ${props => props.theme.palette.colors.white};
`;

export const TextMain = styled.span`
    font-size: 23px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
`;

export const TextSub = styled.span`
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
`;