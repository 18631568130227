import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { breakPoints } from "utils/breakPoints.js";

export const SecurityContainer = styled(Container).attrs({
})`
    background-color: ${props => props.theme.palette.primary.main};
`;

export const RowCentred = styled(Row).attrs({
})`          
    display: flex;
    align-items: center;
    flex-direction: row-reverse
`;

export const ColCustom = styled(Col).attrs({
})`          
    @media ${breakPoints.xs} {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 20px;
    }
`;

export const ColGroup = styled(Col).attrs({
})`          
    display: flex;
    width: 536px;
    flex-direction: column;
    align-items: center;
    padding: 0px 150px;
    flex-shrink: 0;
    gap: 29px;

    @media ${breakPoints.lg} {
        padding: 0px 20px;
    }

    @media ${breakPoints.xs} {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0px 20px;

        display: flex;
        min-height: 100vh;
        justify-content: center;

        position: relative;
        z-index: 999;

        margin: 20px 0px;
    }
`;


export const SecurityBanner = styled.div.attrs({

})`           
    z-index: 999;
    position: relative;
    min-height: 100vh;
    width: 100%;

    background: white url('/icons/banners/securityTwo.jpg') no-repeat top +35% right +30% / cover;

    /* border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px; */
    overflow: hidden;

    @media ${breakPoints.xs} {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
    }
    }
`;

export const Wrap = styled.div.attrs({ 
})`          
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export const IconeLee = styled.img.attrs({ 
    src:'/icons/icon-lee.svg'
})`
    width: 3rem;
`;

export const SecurityTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.white};
    font-family: Montserrat;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.04px;  
`;

export const SecuritySubTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.white};
    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.3px; 
    letter-spacing: 2px;
    text-transform: uppercase;

    @media ${breakPoints.xs} {
        text-align: center;
    }
`;

export const Highlight = styled.span.attrs({
})`          
    color: ${props => props.theme.palette.colors.brightSeaGreen};
`;


export const GradientBox = styled.button.attrs({
})`
    width: 175px;
    height: 50px;
    background: linear-gradient(99deg, #8C01FA 0%, #1F8A74 88%);
    border-radius: 36px;

    border: none;
    cursor: pointer;

    text-align: center;
    color: white;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    word-wrap: break-word;
`;

export const BackButton = styled.span.attrs({
})`          
    color: ${props => props.theme.palette.colors.white};
    cursor: pointer;


    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.3px;
    text-decoration-line: underline;
`