 
import Banner from "components/Landpage/Banner";

import ContainerLandpage from "containers/Landpage";
import SectionTree from "components/Landpage/SectionTree/index.js";
import Shipment from "components/Landpage/Shipment/index.js";
import AppDownloadSection from "components/Landpage/AppDownloadSection";
import SecuritySection from "components/Landpage/SecuritySection/index.js";
import LeePay from "components/Landpage/LeePay/index.js";
import GiftCard from "components/Landpage/GiftCard/index.js";
import Investment from "components/Landpage/Investment/index.js";
import BannerSolo from "components/Landpage/BannerSolo/index.js";
import GatewaySection from "components/Landpage/Gateway/index.js";
import ContactUs from "components/Landpage/ContactUs/index.js";
import CashInCashOutSection from "components/Landpage/CashInCashOutSection/index.js";

export default function Landpage(){ 
 
    return ( 
        <ContainerLandpage> 

                <Banner /> 
                <BannerSolo background={"/icons/banners/section.jpg"} />
                <AppDownloadSection /> 
                <SectionTree />
                <Shipment />
                <BannerSolo background={"/icons/banners/security.jpg"} />
                <SecuritySection />
                <LeePay />
                <GiftCard />
                <Investment />
                <CashInCashOutSection />
                <BannerSolo background={"/icons/banners/people.jpg"} />
                <GatewaySection />
                <ContactUs />
 
        </ContainerLandpage>
    );
}