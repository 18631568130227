import { Container } from 'reactstrap';

import {
    ContactUsContainer, ContactUsTitleContent,
    ContactUsTitle,
    ContactUsText, ColCentred,
    FormContainer,
    Input,
    TextArea,
    WrapFormInput,
    CenterButton
} from './styled';
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';
import { useState } from 'react';

export default function ContactUs() {

    const [form, setForm] = useState({ name: '', email: '', message: '' });

    const formItems = [
        { ref: "name", placeholder: "Nome" },
        { ref: "email", placeholder: "E-mail" },
    ];

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!form.name || !form.email || !form.message) {
            alert("Por favor, preencha todos os campos.");
            return;
        }
    }

    return (
        <>
            <ContactUsContainer>
                <Container>
                    <ColCentred>
                        <ContactUsTitleContent>
                            <ContactUsTitle>Fale conosco</ContactUsTitle>
                            <ContactUsText>Envie sua dúvida preenchendo o formulário ao lado. Responderemos assim que possível!</ContactUsText>
                        </ContactUsTitleContent>

                        <FormContainer onSubmit={handleSubmit}>
                            <WrapFormInput>
                                {formItems.map((item, index) => (
                                    <Input
                                        key={index}
                                        value={form[item.ref]}
                                        onChange={(event) => setForm({ ...form, [item.ref]: event.target.value })}
                                        placeholder={item.placeholder}
                                    />
                                ))}
                            </WrapFormInput>

                            <TextArea
                                value={form.message}
                                onChange={(event) => setForm({ ...form, message: event.target.value })}
                                placeholder='Dúvida'
                            />

                            <CenterButton>
                                <ButtonGradient type="submit" text={'ENVIAR'} textTransform={'uppercase'} />
                            </CenterButton>
                        </FormContainer>
                    </ColCentred>

                </Container>
            </ContactUsContainer>
        </>
    );
}