import { Col } from 'reactstrap';
import styled from 'styled-components'
import { breakPoints } from 'utils/breakPoints.js';

export const BannerImage = styled.div.attrs({
})`           
    z-index: 999;
    position: relative;
    min-height: 770px;
    width: 100%;

    background: white url('/icons/banners/banner.jpg') no-repeat top +35% right +30% / cover;

    @media ${breakPoints.xs} {
        background-position: top +35% right +20%;
    }

    @media ${breakPoints.sm} {
        background-position: top +35% right +20%;
    }

    overflow: hidden;
`;

export const ColCentred = styled(Col).attrs({
    
})`
    flex: 0 0 50%;
    max-width: 50%;

    @media ${breakPoints.lg} {
        flex: 0 0 100%;
        max-width: 100%;
    }

    @media ${breakPoints.sm} {
        flex: 0 0 100%;
        max-width: 100%;
    }
`

export const WrapDarkBackgroud = styled.div.attrs({
})`           
      background: linear-gradient(to top, #1E1E1E, #010101);
    padding: 0 50px 40px;

    @media ${breakPoints.xs} {
        padding: 0 10px 40px;
    }
   
`;

export const BannerOut = styled.div.attrs({
})`            
    min-height: 770px;
    width: 100%;

    background: ${props => props.theme.palette.colors.shadow}; 
    mix-blend-mode: multiply;
`;

export const BannerContent = styled.div.attrs({
})`           
    min-height: 770px;
    width: 100%; 

    background: linear-gradient(to right, rgba(46, 28, 98, 0.8), rgba(69, 51, 138, -2));

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${props => props.theme.palette.secondary.main}",endColorstr="rgba(41,28,68,0)",GradientType=1);
    position: absolute;
    top: 0;
    left: 0;
`;

export const BannerCard = styled.div.attrs({
})`           
`; 