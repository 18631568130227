
import { Row, Col, Container } from 'reactstrap';

import CardSelect from 'components/Landpage/Card/Select';

import {
    BannerImage,
    BannerOut,
    BannerContent, WrapDarkBackgroud,
    ColCentred
} from "./styled";

export default function Banner() {
    return (
        <>
            <WrapDarkBackgroud>
                <BannerImage>
                    <BannerOut />
                    <BannerContent>
                        <Container fluid>
                            <Row>
                                <ColCentred>
                                    <CardSelect />
                                </ColCentred>
                            </Row>
                        </Container>
                    </BannerContent>
                </BannerImage>
            </WrapDarkBackgroud>

        </>
    );
}