import {
    BannerImage,
    WrapDarkBackgroud
} from "./styled";

export default function BannerSolo({ background }) {
    return (
        <WrapDarkBackgroud>
            <BannerImage background={background} />
        </WrapDarkBackgroud>
    );
}