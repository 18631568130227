import { Col } from 'reactstrap';
import styled from 'styled-components'
import { breakPoints } from 'utils/breakPoints.js';


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0 50px 50px;

    @media ${breakPoints.xs} {
        padding: 0 10px 50px;
    }
   
`;

export const GiftCardIamge = styled.div.attrs({
})`           
    z-index: 999;
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: white url('/icons/banners/giftcard.jpg') no-repeat top +35% right +30% / cover;
    overflow: hidden;

    

    @media ${breakPoints.lg} {
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
    }
    }

    @media ${breakPoints.xs} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

export const ColGroup = styled(Col).attrs({
})`          
    display: flex;
    width: 536px;
    padding: 254px 143px 228px 143px;
    flex-direction: column;
    flex-shrink: 0;
    gap: 20px;

    @media ${breakPoints.lg} {
        padding: 10px 50px;
        width: 100%;
        gap: 50px;
    }

    @media ${breakPoints.xs} {
        padding: 10px 10px;
        width: 100%;
    }

    z-index: 999;
`;

export const GiftCardTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.white};
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.04px; 

    z-index: 2; 


    border-left: 3px solid ${props => props.theme.palette.colors.cyan};
    padding-left: 20px;

    @media ${breakPoints.lg} {
        font-size: 50px;
    }

    @media ${breakPoints.xs} {
        font-size: 50px;
    }
`;

export const GiftCardSubTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.white};
    font-family: Montserrat;
    font-size: 17.7px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.3px;
    z-index: 2;

`;

export const GradientBox = styled.button.attrs({
})`
    padding: 10px;
    background-color: transparent;

    border-radius: 12px;
    border: 2px solid ${props => props.theme.palette.colors.brightSeaGreen};
    gap: 12px;

    z-index: 2;


    color: ${props => props.theme.palette.colors.brightSeaGreen};
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word;

    margin: 0px 20px;
`;