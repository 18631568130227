import { Col, Row } from 'reactstrap';
import styled from 'styled-components'
import { breakPoints } from 'utils/breakPoints.js';


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 50px 50px 50px;

    @media ${breakPoints.xs} {
        padding: 0 10px 40px;
    }
   
`;

export const BackgrounGradient = styled.div.attrs({
})`           
    z-index: 999;
    position: relative;
    min-height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(47,86,223,1) 0%, rgba(92,51,155,1) 100%);

    overflow: hidden;

    @media ${breakPoints.sm} {
        padding: 50px 0px;
    }
`;

export const RowCentred = styled(Row).attrs({
})`          
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    flex-wrap: wrap;

    @media ${breakPoints.xs} {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @media ${breakPoints.sm} {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const ColCentered = styled(Col).attrs({
    
})`


`

export const CartPayment = styled.img.attrs({
    src: `/icons/cartPayment.svg`
})`
    width: 750px;
    position: static;
    left: 0;

    @media ${breakPoints.md} {
        width: 75vw;
        right: 17vw;
        position: relative;
    }

    @media ${breakPoints.sm} {
        width: 40rem;
        position: relative;
        left: 1vw;
    }

    @media ${breakPoints.xs} {
        width: 18rem;
        position: static;
        left: 0;
    }
`;

export const TitleService = styled.div.attrs({})`
    font-size: 88px;
    font-weight: 400;
    line-height: 102.5px;
    color: ${props => props.theme.palette.colors.white};

    padding-bottom: 30px;

    @media (max-width: 767px) {
        font-size: 50px;
        line-height: 60px;
        text-align: center;
    }
`;

export const Subtitle = styled.div.attrs({
})`           
    text-align: justify;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 400;
    line-height: 27.3px;

    padding-bottom: 50px;

    color: ${props => props.theme.palette.colors.white};

    @media ${breakPoints.xs} {
        text-align: center;
    }
`; 

export const ButtonCash = styled.button.attrs({
    type: 'button'
})`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 

    width: 100%;
    height: 45px;
    background: transparent;

    color: ${props => props.theme.palette.colors.brightSeaGreen};
    
    border: 2px solid ${props => props.theme.palette.colors.brightSeaGreen};
    border-radius: 10px;
    cursor: pointer;

    @media ${breakPoints.xs} {
        font-size: 14px;
    }
`;
