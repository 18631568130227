
import {
    CardBanner,
    CardBannerTitle,
    CardBannerText, IconeLee,
    Wrap
} from "./styled";


export default function CardSelect() {

    return (
        <>
            <CardBanner>
                <Wrap>
                    <IconeLee />
                    <CardBannerTitle>Nosso futuro <br /> Começa aqui</CardBannerTitle>
                </Wrap>
                <CardBannerText>
                    O Lee Bank nasceu com o intuito de oferecer serviços bancários com agilidade e segurança para os mais de 300 mil chineses que vivem no Brasil. Somos um banco personalizado que presta serviços financeiros com atendimento na sua língua nativa.
                </CardBannerText>
            </CardBanner>
        </>
    );
}