
import { Container } from 'reactstrap';


import {
    ColGroup,
    GiftCardIamge,
    GiftCardSubTitle,
    GiftCardTitle,
    GradientBox,
    WrapDarkBackgroud
} from "./styled";

export default function GiftCard() {
    return (
        <WrapDarkBackgroud>
            <GiftCardIamge>

                <Container fluid>
                    <ColGroup md={4}>
                        <GiftCardTitle>Gift card</GiftCardTitle>
                        <GiftCardSubTitle>
                            Quer praticidade? Compre Gift cards online das maiores empresas de entretenimento, transporte e alimentação para você ou para presentear. Recarregue o seu celular usando nossos serviços sem precisar sair de casa.
                        </GiftCardSubTitle>
                        <GradientBox>
                            Adquira o seu Gift card
                        </GradientBox>
                    </ColGroup>
                </Container>
            </GiftCardIamge>
        </WrapDarkBackgroud>
    );
}