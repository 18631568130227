import styled from "styled-components";

export const GradientBox = styled.button.attrs({

})`
    width: 175px;
    height: 50px;
    background: linear-gradient(99deg, #8C01FA 0%, #1F8A74 88%);
    border-radius: 36px;

    border: none;
    cursor: pointer;

    text-align: center;
    color: white;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: ${props => props.textTransform || 'none'};
    line-height: 20px;
    word-wrap: break-word;
`;