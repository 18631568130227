import styled from 'styled-components' 
import { breakPoints } from 'utils/breakPoints.js';



export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0 50px;

     @media ${breakPoints.xs} {
        padding: 0 10px 40px;
    }
   
`;

export const BannerImage = styled.div.attrs({ 
})`           
    position: relative;
    min-height: 100vh;
    width: 100%;

    background: ${({ background }) => `white url(${background}) no-repeat center / cover`};

     @media ${breakPoints.xs} {
         background-position: top center;
    }

    /* border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px; */
    overflow: hidden;
`; 