
import { Col } from 'reactstrap';


import {
    InvestmentBanner,
    GradientBox,
    InvestmentTitle,
    InvestmentSubTitle,
    InvestmentContainer,
    RowCentred,
    ColGroup,
    WrapDarkBackgroud
} from "./styled";
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';

export default function Investment() {
    return (
        <WrapDarkBackgroud>
            <InvestmentContainer fluid>
                <RowCentred>
                    <Col md={7} className="p-0">
                        <InvestmentBanner />
                    </Col>
                    <ColGroup md={2}>
                        <InvestmentTitle>Investimento</InvestmentTitle>
                        <InvestmentSubTitle>
                            Invista com segurança. Nós temos diferentes opções de investimentos para você aplicar o seu dinheiro onde preferir. Acompanhe os rendimentos de perto e se informe sobre o mercado financeiro.
                            Faça o seu dinheiro render com o Lee Bank aqui no Brasil, como você faria na China.
                        </InvestmentSubTitle>
                        <ButtonGradient text={'SAIBA MAIS'} textTransform={'uppercase'} />
                    </ColGroup>
                </RowCentred>
            </InvestmentContainer>
        </WrapDarkBackgroud>
    );
}