const size = {
  xxs: '320px',  
  xs: '480px',   
  sm: '768px',   
  md: '992px',   
  lg: '1200px',  
  xl: '1440px',  
  xxl: '1920px'  
};

export const breakPoints = {
  xxs: `(max-width: ${size.xxs})`,
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`
};
