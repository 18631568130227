import { Col, Container, Row } from "reactstrap";
import { BackgrounGradient, ButtonCash, CartPayment, ColCentered, RowCentred, Subtitle, TitleService, WrapDarkBackgroud } from "./styled.js";
import CardSelect from "../Card/Select/index.js";

export default function SectionTree() {
    return (
        <WrapDarkBackgroud>
            <BackgrounGradient>
                <Container fluid>
                    <RowCentred>
                        <ColCentered>
                            <CartPayment />
                        </ColCentered>
                        <ColCentered>
                            <TitleService>
                                Serviço completo no banco
                            </TitleService>

                            <Subtitle>
                                Somos um banco digital completo
                                para quem quer soluções financeiras na vida e nos
                                negcios na palma da mão.
                            </Subtitle>

                            <ButtonCash>
                                Soluções em cash-in e cash out
                            </ButtonCash>
                        </ColCentered>
                    </RowCentred>
                </Container>
            </BackgrounGradient>
        </WrapDarkBackgroud>
    );
}