import { Col } from 'reactstrap';
import styled from 'styled-components';
import { breakPoints } from 'utils/breakPoints.js';


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0 50px 50px;
   
    @media ${breakPoints.xs} {
        padding: 0 10px 50px;
    }
`;

export const BannerImage = styled.div.attrs({
})`           
    z-index: 999;
    position: relative;
    min-height: 100vh;
    width: 100%;

    display: flex;       
    flex-direction: column;
    justify-content: flex-end;

    padding: 50px 0px;

    background: linear-gradient(182deg, rgba(207, 182, 255, 0.00) 1.36%, #2B007B 98.61%), 
                url('/icons/banners/cash.jpg') no-repeat top +35% right +30% / cover;
    background-blend-mode: multiply;

    overflow: hidden;
`;

export const ColCentered = styled(Col)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: inline-flex;
`

export const CashInCashOutTitle = styled.div.attrs({})`
    color: ${props => props.theme.palette.colors.white};
    text-align: center;
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: 102.5px;
    padding-bottom: 20px;

    @media ${breakPoints.sm} {
        font-size: 50px;
        line-height: normal;
    }

    @media ${breakPoints.xs} {
        font-size: 50px;
        line-height: normal;
    }
`
export const Subtitle = styled.div.attrs({})`
    color: ${props => props.theme.palette.colors.white};
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27.3px;
    padding-bottom: 20px;

    @media ${breakPoints.xs} {
        font-size: 16px;
        text-align: center;
    }
`

export const Button = styled.button.attrs({
    type: 'button'
})`
    color: ${props => props.theme.palette.colors.black};
    background: ${props => props.theme.palette.colors.brightSeaGreen};
    border: none;
    border-radius: 50px;
    padding: 15px 15px;
    
    cursor: pointer;
    margin-top: 30px;
    
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
`