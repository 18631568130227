import { Col } from 'reactstrap';
import styled from 'styled-components';
import { breakPoints } from 'utils/breakPoints.js';

export const ContainerCentered = styled.div.attrs({
})`          

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${props => props.theme.palette.primary.main};
    min-height: 100vh;
`;

export const BannerCash = styled.img.attrs({
    src: '/icons/banners/cashin.jpg',
})`
    width: 100%;
`

export const ColCentered = styled(Col)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: inline-flex;

    padding: 60px 0px;

    background-color: ${props => props.theme.palette.colors.white};
`

export const CashInCashOutTitle = styled.div.attrs({})`
    color: ${props => props.theme.palette.colors.white};
    background: linear-gradient(262deg, #19FB9B -8.01%, #8C01FA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: 102.5px;
    padding-bottom: 20px;

    @media ${breakPoints.sm} {
        font-size: 60px;
        line-height: normal;
    }
`
export const Subtitle = styled.div.attrs({})`
    color: ${props => props.theme.palette.colors.purple};
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.3px;
    text-transform: uppercase;

    padding: 0px 20px;
`

export const Highlight = styled.span.attrs({

})`
    padding: 0px 10px;
 
`

export const BackButton = styled.span.attrs({
})`          

    padding-top: 40px;

    color: ${props => props.theme.palette.colors.white};
    cursor: pointer;


    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.3px;
    text-decoration-line: underline;
`