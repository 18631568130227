import { Col } from 'reactstrap';
import styled from 'styled-components';
import { breakPoints } from 'utils/breakPoints.js';


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0 50px 0px;

    @media ${breakPoints.xs} {
        padding: 0 10px 0px;
    }
   
`;

export const GatewaySectionContainer = styled.div.attrs({
})`          
    position: relative;
    background-color: ${props => props.theme.palette.colors.white};
    padding-bottom: 50px;
`;

export const ColCentred = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const GatewaySectionTitleContent = styled.div.attrs({
})`            
    padding: 40px 0;
`;


export const GatewaySectionTitle = styled.div.attrs({
})`            
    font-size: 88px;
    text-align: center;
    color: ${props => props.theme.palette.colors.white};

    background: linear-gradient(262deg, #19FB9B -8.01%, #8C01FA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;

    @media ${breakPoints.xs} {
        font-size: 40px;
        line-height: normal;
        padding-bottom: 20px;
    }
`;

export const GatewaySectionText = styled.div.attrs({
})`            
    font-size: 20px;
    color: ${props => props.theme.palette.colors.purple};
    font-weight: 400;
    line-height: 27.3px;
    text-align: center;
    text-transform: uppercase;

    @media ${breakPoints.xs} {
        font-size: 18px;
    }
`;