import CashInCashOut from "components/Landpage/CashInCashOut/index.js";
import ContainerLandpage from "containers/Landpage/index.js";

export default function CashInCashOutPage(){ 
 
  return ( 
      <ContainerLandpage> 
        <CashInCashOut />
      </ContainerLandpage>
  );
}