import Security from "components/Landpage/Security/index.js";
import ContainerLandpage from "containers/Landpage/index.js";

export default function SecurityPage(){ 
 
  return ( 
      <ContainerLandpage> 
        <Security />
      </ContainerLandpage>
  );
}