
import { Col } from 'reactstrap';

import {
    SecurityBanner, SecurityTitle,
    SecuritySubTitle,
    SecurityContainer,
    RowCentred,
    ColGroup,
    IconeLee,
    Wrap,
    Highlight,
    BackButton,
    ColCustom
} from "./styled";
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function Security() {
    const history = useHistory();

    const goBack = () => history.goBack();

    return (
        <>
            <SecurityContainer fluid>
                <RowCentred>
                    <ColCustom className="p-0">
                        <SecurityBanner />
                    </ColCustom>
                    <ColGroup>
                        <Wrap>
                            <IconeLee />
                            <SecurityTitle>Seguro</SecurityTitle>
                        </Wrap>
                        <SecuritySubTitle>
                            Durma com tranquilidade sabendo que sua família está segura conosco. fazer um seguro é bem simples. basta entrar no campo <Highlight>Seguros</Highlight> do nosso app e escolher quais tipos você quer. pronto! simples, prático, fácil e na palma da sua mão. <br/> <br/>
                            consulte abaixo o seguro que mais se adequa às suas necessidades.
                        </SecuritySubTitle>
                        <ButtonGradient text={'nossos seguros'} textTransform={'uppercase'} />

                        <BackButton onClick={goBack}>Voltar</BackButton>
                    </ColGroup>
                </RowCentred>
            </SecurityContainer>
        </>
    );
}