
import { Row, Col, Container } from 'reactstrap';


import {
    BannerImage,
    ShipmentTitle,
    Subtitle,
    ButtonPF, ColCentered,
    WrapDarkBackgroud,
    RowCenter
} from "./styled";

export default function Shipment() {
    return (
        <WrapDarkBackgroud>
            <BannerImage>
                <Container>
                    <ColCentered md={{ size: 12 }}>
                        <ShipmentTitle>
                            Remessa
                        </ShipmentTitle>

                        <Subtitle>
                            Com o nosso serviço de câmbio é possível fazer remessas para fora do país, de forma rápida e segura. Se você precisar enviar dinheiro para amigos e familiares no exterior, conte conosco. Oferecemos serviços também para pessoas jurídicas, tudo de forma transparente e sem surpresas no final. Quer saber mais? Clique no botão abaixo de acordo com o seu perfil:
                        </Subtitle>

                        <RowCenter>
                            <Col md={6}>
                                <ButtonPF>
                                    Pessoa física
                                </ButtonPF>
                            </Col>
                            <Col md={6}>
                                <ButtonPF>
                                    Pessoa jurídica
                                </ButtonPF>
                            </Col>
                        </RowCenter>
                    </ColCentered>
                </Container>
            </BannerImage>
        </WrapDarkBackgroud>
    );
}