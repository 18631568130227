import { Col } from 'reactstrap';
import styled from 'styled-components';
import { breakPoints } from 'utils/breakPoints.js';

export const ContactUsContainer = styled.div.attrs({
})`          
    background-color: #202122;

    padding: 140px 0;

    @media ${breakPoints.xs} {
        padding: 10px 0;
    }
`;

export const ColCentred = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ContactUsTitleContent = styled.div.attrs({
})`            
    padding: 20px 0;
`;

export const FormContainer = styled.form.attrs({
})`            
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
`;

export const CenterButton = styled.div.attrs({
})`            
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const WrapFormInput = styled.div.attrs({
})`            
    display: flex;
    width: 70%;
    flex-direction: row;
    gap: 20px;

    @media ${breakPoints.xs} {
        width: 100%;
    }
`;

export const Input = styled.input.attrs({
})`            
    width: 100%;
    height: 50px;
    border-radius: 14px;
    border: 1px solid ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.colors.white};
    color: ${props => props.theme.palette.colors.black};
    padding-left: 20px;
    margin-bottom: 20px;    

    &::placeholder {
        color: ${props => props.theme.palette.colors.black};
    }   
`;

export const TextArea = styled.textarea.attrs({
})`            
    width: 100%;
    height: 200px;
    border-radius: 14px;
    border: 1px solid ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.palette.colors.white};
    color: ${props => props.theme.palette.colors.black};
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 20px;

    &::placeholder {
        color: ${props => props.theme.palette.colors.black};
    }   
`;

export const ContactUsTitle = styled.div.attrs({
})`            
    font-size: 40px;
    text-align: center;
    color: ${props => props.theme.palette.colors.white};

    background: linear-gradient(262deg, #19FB9B -8.01%, #8C01FA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;

    padding-bottom: 20px;
`;

export const ContactUsText = styled.div.attrs({
})`            
    font-size: 21px;
    color: ${props => props.theme.palette.colors.white};
    font-weight: 400;
    line-height: 27.3px;
    text-align: center;
`;