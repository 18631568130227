
import { Container } from 'reactstrap';


import {
    GatewaySectionContainer, GatewaySectionTitleContent,
    GatewaySectionTitle,
    GatewaySectionText, ColCentred,
    WrapDarkBackgroud
} from './styled';
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';

export default function GatewaySection() {

    return (
        <WrapDarkBackgroud>
            <GatewaySectionContainer>
                <Container>
                    <ColCentred>
                        <GatewaySectionTitleContent>
                            <GatewaySectionTitle>Gateway de pagamento</GatewaySectionTitle>
                            <GatewaySectionText>nós temos a solução ideal para a sua loja online. deixe os seus clientes mais seguros na hora de fazer as compras. comece agora mesmo a utilizar nosso gateway de pagamento através da nossa plataforma que oferece os dados completos e taxas mais baratas.</GatewaySectionText>
                        </GatewaySectionTitleContent>


                       <ButtonGradient text={'SAIBA MAIS'} textTransform={'uppercase'}/>
                    </ColCentred>

                </Container>
            </GatewaySectionContainer>
        </WrapDarkBackgroud>
    );
}