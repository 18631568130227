
import { Row, Col, Container } from 'reactstrap';


import {
    BannerImage,
    CashInCashOutTitle,
    Subtitle, ColCentered,
    Button,
    WrapDarkBackgroud
} from "./styled";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function CashInCashOutSection() {
    const history = useHistory();
    
    const navigate = to => history.push(`/${to}`);

    return (
        <WrapDarkBackgroud>
            <BannerImage>
                <Container>
                    <ColCentered md={{ size: 12 }}>
                        <CashInCashOutTitle>
                            Cash in e Cash out
                        </CashInCashOutTitle>

                        <Subtitle>
                            Contamos com a maior praticidade quando se fala em Cash in e Cash out. No Lee Bank você consegue sacar dinheiro em qualquer caixa eletrônico 24h. Se precisar depositar dinheiro na sua conta Lee Bank, basta ir à uma agência dos bancos parceiros mais próxima para fazer o depósito.
                        </Subtitle>

                        <Row>
                            <Col md={12}>
                                <Button onClick={() => navigate('cash')}>
                                    SAIBA MAIS
                                </Button>
                            </Col>
                        </Row>
                    </ColCentered>
                </Container>
            </BannerImage>
        </WrapDarkBackgroud>
    );
}