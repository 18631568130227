
import { Row, Col, Container } from 'reactstrap';


import {

    QrCode, AppDownloadSectionContainer, AppDownloadSectionTitleContent,
    AppDownloadSectionTitle,
    AppDownloadSectionText, GroupText, ColGroupButton,
    RowCentred,
    WrapQrcode,
    buttonData,
    Button,
    TextMain,
    TextSub,
    WrapDarkBackgroud
} from './styled';
import QRCodeComponent from '../QrCodeComponent/index.js';

export default function AppDownloadSection() {

    return (
        <WrapDarkBackgroud>
            <AppDownloadSectionContainer>
                <Container>
                    <Row>
                        <Col>
                            <AppDownloadSectionTitleContent>
                                <AppDownloadSectionTitle>Digital, intuitivo e eficiente</AppDownloadSectionTitle>
                                <AppDownloadSectionText>Aponte sua câmera do celular para o QR Code ou baixe o app pelo Google play ou App Store e conheça  uma nova experiência de banco aqui.</AppDownloadSectionText>
                            </AppDownloadSectionTitleContent>
                        </Col>
                    </Row>
                    <RowCentred>
                        <WrapQrcode>
                            <QRCodeComponent url="https://api.leebank.finance/api/ops/redirect" />
                        </WrapQrcode>
                        <ColGroupButton md={{ size: 6 }}>
                            {buttonData.map(({ id, Icon, mainText, subText, link }) => (
                                <Button key={id}>
                                    <Icon />
                                    <GroupText href={link} target="_blank">
                                        <TextMain>{mainText}</TextMain>
                                        <TextSub>{subText}</TextSub>
                                    </GroupText>
                                </Button>
                            ))}
                        </ColGroupButton>
                    </RowCentred>
                </Container>
            </AppDownloadSectionContainer>
        </WrapDarkBackgroud>
    );
}