
import { Col } from 'reactstrap';


import {
    LeePayBanner,
    GradientBox,
    LeePayTitle,
    LeePaySubTitle,
    LeePayContainer,
    RowCentred,
    ColGroup,
    WrapDarkBackgroud
} from "./styled";
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';

export default function LeePay() {
    return (
        <WrapDarkBackgroud>
            <LeePayContainer fluid>
                <RowCentred>
                    <Col md={7} className="p-0">
                        <LeePayBanner />
                    </Col>
                    <ColGroup md={2}>
                        <LeePayTitle>LEE PAY</LeePayTitle>
                        <LeePaySubTitle>
                            Com a maquininha do Lee Bank, você tem soluções do início ao fim das suas vendas e, o melhor, na língua que você escolher. Tenha acesso aos relatórios de entrada e saída, extratos e saldos em três idiomas diferentes.
                        </LeePaySubTitle>
                        <ButtonGradient text={'SAIBA MAIS'} textTransform={'uppercase'} />
                    </ColGroup>
                </RowCentred>
            </LeePayContainer>
        </WrapDarkBackgroud>
    );
}