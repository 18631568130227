import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

const QRContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin: 20px; 
`;

const QRCodeComponent = ({ url }) => {
  return (
    <QRContainer>
      <QRCode
        value={url} 
        size={256}
        fgColor={"#000000"} 
        level={"Q"}
      />
    </QRContainer>
  );
};

export default QRCodeComponent;
