import { useState } from 'react';

import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import {
    HeaderContainer,
    RowCenter,
    RowEnd,
    AppLogo, HeaderMenuItem,
    HeaderMobile,
    MenuIcon,
    HeaderMobileMenu,
    HeaderMobileItem,
    RowCentred,
    ColCentred
} from './styled';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
    Touch
} from 'ui/styled';
import Button from 'components/Form/Button';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const history = useHistory();

    const navigate = to => history.push(`/${ to }`);

    const options = {
        left: [
            { title: 'Produtos', active: true, action: () => navigate(''), showArrow: true },
            { title: 'Serviços', action: () => navigate(''), showArrow: true },
            { title: 'Ajuda', action: () => navigate(''), showArrow: true },
            { title: 'Idioma', action: () => navigate(''), showArrow: true },
            { title: 'Cadastre-se', action: () => navigate('') },
        ],
        right: [
            { title: 'Login', button: true, action: () => navigate('login') },
            { title: 'Registro', button: true, outline: true, action: () => navigate('register') },
        ]
    }

    return (
        <>
            <HeaderContainer>
                <Container fluid>
                    <RowCentred>
                        <ColCentred>
                            <RowCenter>
                                <AppLogo />
                                <HeaderMobile>
                                    <Touch className="touch" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                        <MenuIcon />
                                    </Touch>
                                    {
                                        mobileMenuOpen ?
                                            <HeaderMobileMenu>
                                                {
                                                    // [...options?.left, ...options?.right]?.map((item, key) => item.button ?
                                                    [...options?.left]?.map((item, key) => item.button ?
                                                        <HeaderMobileItem key={key} centred>
                                                            <Button nospace outline={item.outline} nomargin onClick={item.action}>{item.title}</Button>
                                                        </HeaderMobileItem>
                                                        :
                                                        <HeaderMobileItem key={key} active={item.active} onClick={item.action}>{item.title}</HeaderMobileItem>
                                                    )
                                                }
                                            </HeaderMobileMenu>
                                            : null
                                    }
                                </HeaderMobile>
                            </RowCenter>
                        </ColCentred>
                        <Col md={{ size: 6 }}>
                            <RowEnd>
                                {
                                    options?.left?.map((item, key) => item.button ?
                                        <Button key={key} nospace outline={item.outline} onClick={item.action}>{item.title}</Button>
                                        :
                                        <HeaderMenuItem key={key} active={item.active} onClick={item.action}>
                                            {item.title}
                                            {item.showArrow && <KeyboardArrowDownIcon />}
                                        </HeaderMenuItem>
                                    )
                                }
                            </RowEnd>
                        </Col>
                    </RowCentred>
                </Container>
            </HeaderContainer>
        </>
    )
}