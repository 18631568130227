import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { breakPoints } from "utils/breakPoints.js";


export const WrapDarkBackgroud = styled.div.attrs({
})`           
    background-color: ${props => props.theme.palette.colors.dark};
    padding: 0px 50px 50px;

    @media ${breakPoints.xs} {
        padding: 0 10px 50px;
    }
   
`;

export const LeePayContainer = styled(Container).attrs({
})`          
    background-color: ${props => props.theme.palette.colors.white};
`;

export const RowCentred = styled(Row).attrs({
})`          
    display: flex;
    align-items: center;

    @media ${breakPoints.lg} {
        flex-wrap: nowrap;
    }

    @media ${breakPoints.sm} {
        flex-wrap: wrap;
    }
`;

export const ColGroup = styled(Col).attrs({
})`          
    display: flex;
    width: 536px;
    padding: 254px 143px 228px 143px;
    flex-direction: column;
    flex-shrink: 0;
    gap: 20px;

    @media ${breakPoints.lg} {
        padding: 254px 13px 228px;
        width: 40%;
    }

    @media ${breakPoints.sm} {
        padding: 20px 10px;
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
`;


export const LeePayBanner = styled.div.attrs({
    src: `/icons/banners/leepay.jpg`
})`           
    z-index: 999;
    position: relative;
    min-height: 100vh;
    width: 100%;

    background: white url('/icons/banners/leepay.jpg') no-repeat top +35% right +30% / cover;

    /* border-bottom-left-radius: 50% 80px;
    border-bottom-right-radius: 50% 80px; */
    overflow: hidden;

    @media ${breakPoints.sm} {
      display: none;
    }
`;

export const LeePayTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.purple};
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.04px; 

    border-left: 3px solid ${props => props.theme.palette.colors.cyan};
    padding-left: 20px;
`;

export const LeePaySubTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.colors.purple};
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.3px;
`;

export const GradientBox = styled.button.attrs({
})`
    width: 175px;
    height: 50px;
    background: linear-gradient(99deg, #8C01FA 0%, #1F8A74 88%);
    border-radius: 36px;

    border: none;
    cursor: pointer;

    text-align: center;
    color: white;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    word-wrap: break-word;
`;