
import { Container } from 'reactstrap';


import {
    SecuritySectionContainer,
    SecuritySectionDecoration,
    SecuritySectionTitleContent,
    SecuritySectionTitle,
    SecuritySectionText, ColCentred,
    WrapDarkBackgroud
} from './styled';
import { ButtonGradient } from 'components/Form/ButtonGradient/index.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function SecuritySection() {
    const history = useHistory();
    
    const navigate = to => history.push(`/${to}`);

    return (
        <WrapDarkBackgroud>
            <SecuritySectionContainer>
                <Container>
                        <ColCentred>
                            <SecuritySectionTitleContent>
                                <SecuritySectionTitle>Seguro</SecuritySectionTitle>
                                <SecuritySectionText>Com os seguros Lee Bank, você protege o que conquistou e o que realmente importa. Deixa sua vida livre de preocupações e tenha mais tempo para você e para quem ama.</SecuritySectionText>
                            </SecuritySectionTitleContent>

                            
                            <ButtonGradient text={'SAIBA MAIS'} textTransform={'uppercase'} onClick={() => navigate('security')} />
                        </ColCentred>

                </Container>
            </SecuritySectionContainer>
        </WrapDarkBackgroud>
    );
}