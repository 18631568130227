


import {
    CashInCashOutTitle,
    Subtitle, ColCentered, BannerCash,
    ContainerCentered,
    Highlight,
    BackButton
} from "./styled";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

export default function CashInCashOut() {
    const history = useHistory();

    const goBack = () => history.goBack();

    return (
        <>
            <ContainerCentered>
                <BannerCash />

                <ColCentered md={{ size: 12 }}>

                    <CashInCashOutTitle>
                        Cash in e Cash out
                    </CashInCashOutTitle>

                    <Subtitle>
                        com o cash-in do lee bank você consegue pagar boletos, recarregar o seu celular e fazer depósito em conta do lee bank no caixa eletrônico ou lotérica mais próxima de você. receba o valor depositado em até 1 dia útil. veja as agências parceiras. <br /> <br />

                        <Highlight>o nosso serviço de cash-out permite você sacas o seu dinheiro em conta lee bank nos caixas 24h.</Highlight>
                    </Subtitle>

                </ColCentered>

                <BackButton onClick={goBack}>Voltar</BackButton>
            </ContainerCentered>
        </>
    );
}