import styled from 'styled-components' 
import { breakPoints } from 'utils/breakPoints.js';

export const IconeLee = styled.img.attrs({ 
    src:'/icons/icon-lee.svg'
})`
    width: 7rem;

    @media ${breakPoints.xs} {
        width: 3rem;
    }
    
`;

export const Wrap = styled.div.attrs({
    
})`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px
`

export const CardBanner = styled.div.attrs({ 
})`           
    border-radius: 17px;
    padding: 24px 25px 30px;
    margin: 100px 0 50px;

    @media(max-width: 767px){
        margin: 30px 0 50px;
    }
`; 
 

export const CardBannerTitle = styled.div.attrs({ })`
    font-size: 80px;
    line-height: 80px;
    font-weight: 400;
    text-align: center;
    color: ${props => props.theme.palette.colors.white};

    background: linear-gradient(262deg, #19FB9B -8.01%, #B352FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
        font-size: 28px;
        line-height: 32px;
    }
`;

export const CardBannerText = styled.div.attrs({ 
})`           
    font-size: 23px;
    text-align: justify;
    color: ${ props => props.theme.palette.colors.white };
    margin: 12px 0 20px;
    font-weight: 400;
    padding-right: 30px;
    line-height: 27.3px;
    b{
        color: ${ props => props.theme.palette.primary.main };
    }

    @media ${breakPoints.xs} {
        text-align: center;
    }
`; 
 